import React, { useCallback, useEffect, useState } from 'react';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import { HeaderScreen } from '../HeaderScreen';
import { ScreenName } from '../ScreenName';
import { BoletoInfoModal, FaturasContent } from './styles';
import { Box } from '@material-ui/core';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { DadosConcluir } from '../DadosConcluir';
import { serviceMeuPlano } from '../../services/meu-plano';
import { nanoid } from 'nanoid';
import { format } from 'date-fns';
import { moneyFormat } from '~/utils/functions';

import { CardContentProps, CreditCardInfo, Fatura } from '../../types/Fatura';
import { formatCurrencyAsText } from '~/utils/functions';
import { toast } from 'react-toastify';
import Card from 'react-credit-cards-2';

// import dataMock from '../../mock/faturas.json';

const MySwal = withReactContent(Swal);

export const Invoices: React.FC = () => {
  const {
    screen,
    handleScreen,
    setChaveBoleto,
    setBoletoURL,
    isDemonstracao,
    tipoStatus,
  } = useMeuPlano();
  const [rows, setRows] = useState<Fatura[]>([]);

  function removeMFromArray(arr: any[]): any[] {
    return arr.map((item) => {
      // Verifica se o objeto contém a chave "M" e a remove
      if (item.M) {
        return item.M; // Retorna o conteúdo de M, que é o objeto desejado
      }
      return item;
    });
  }

  const getFaturas = useCallback(async () => {
    const { data } = await serviceMeuPlano.index('/index-faturas');
    const success = true;

    if (success) {
      if (data.length < 1) {
        toast.warning('Não foram encontradas faturas');
        return;
      }

      const { faturas } = data[0];

      const opt = removeMFromArray(faturas).map((value: Fatura) => {
        const { forma_pagamento } = value;
        let des_dados_json = {} as CreditCardInfo;
        if (forma_pagamento) {
          des_dados_json = forma_pagamento?.des_dados_json;
          if (typeof value.forma_pagamento.des_dados_json === 'string') {
            des_dados_json = JSON.parse(value.forma_pagamento.des_dados_json);
          }
        }
        return {
          id: nanoid(),
          dta_vencimento: format(
            new Date(value.dta_vencimento || new Date()),
            'dd/MM/yyyy',
          ),
          des_nome: value.des_nome,
          val_cobranca: moneyFormat(Number(value.val_cobranca).toFixed(2)),
          des_status: value.des_status,
          cod_seq_ciclo: value.cod_seq_ciclo,
          cod_aplicacao: value.cod_aplicacao,
          cod_contratacao: value.cod_contratacao,
          cod_cliente: value.cod_cliente,
          tipo_status: value.tipo_status,
          flg_ativo: value.flg_ativo,
          des_fatura_url: value.des_fatura_url,
          des_fatura_comprovante_url: value.des_fatura_comprovante_url,
          des_boleto_url: value.des_boleto_url,
          des_boleto_nosso_numero: value.des_boleto_nosso_numero,
          des_boleto_campo_identificacao: value.des_boleto_campo_identificacao,
          des_boleto_codigo_barras: value.des_boleto_codigo_barras,
          tipo_forma_pagamento: value.tipo_forma_pagamento,
          forma_pagamento: {
            ...value.forma_pagamento,
            des_dados_json,
          },
        };
      });

      setRows(opt);
    }
  }, []);

  function getComprovante(url: string) {
    window.open(url, '_blank')?.focus();
  }

  useEffect(() => {
    if (screen === 'FATURAS') getFaturas();
  }, [getFaturas, screen]);

  const BoletoContent = ({ onViewBoleto }: { onViewBoleto: () => void }) => (
    <BoletoInfoModal>
      <p>Pagamentos via boleto podem levar até 24h para serem identificados.</p>
      <p>
        Para acesso imediato, prefira <strong>Cartão de Crédito</strong>.
      </p>
      <button
        className="action text-white font-bold py-1 px-4 rounded"
        type="button"
        onClick={onViewBoleto}
      >
        Visualizar Boleto
      </button>
    </BoletoInfoModal>
  );

  const CardContent: React.FC<CardContentProps> = ({
    creditCardBrand,
    lastDigits,
    dueDate,
    price,
  }) => {
    const translations = {
      valid: 'Válido até',
      month: 'Mês',
      year: 'Ano',
      cvc: 'Código de Segurança',
      cardHolder: 'Titular do Cartão',
      number: 'Número do Cartão',
    };
    return (
      <div>
        <small>
          O pagamento com cartão é processado automaticamente no momento da
          renovação do ciclo.
        </small>
        <Card
          number={`**** **** **** ${lastDigits}`}
          expiry={dueDate}
          cvc=""
          name={`R$${formatCurrencyAsText(price)}`}
          focused=""
          preview
          locale={translations}
          placeholders={{ name: 'Seu Nome Aqui' }}
          issuer={creditCardBrand}
        />
      </div>
    );
  };

  const PaymentStatus = () => (
    <div>
      <h3>Pagamento processado com sucesso</h3>
    </div>
  );

  const modalAlert = useCallback(
    async (row: Fatura) => {
      const isBoleto = row.tipo_forma_pagamento === 0;
      let currentContent = isBoleto ? 'BoletoContent' : 'CartaoContent';

      const paymentSuccess = false;

      if (
        currentContent === 'BoletoContent' ||
        currentContent === 'BoletoView'
      ) {
        setChaveBoleto(row.des_boleto_campo_identificacao || '');
        setBoletoURL(row.des_boleto_url || '');
      }
      const renderContent = () => {
        if (currentContent === 'BoletoContent') {
          return (
            <BoletoContent
              onViewBoleto={() => {
                currentContent = 'BoletoView';
                renderModal('modal-wide');
              }}
            />
          );
        }
        if (currentContent === 'BoletoView') {
          setChaveBoleto(row.des_boleto_campo_identificacao || '');
          return (
            <DadosConcluir
              faturamento="boleto"
              codBarrasBoleto={String(row.des_boleto_campo_identificacao)}
              urlBoleto={String(row.des_boleto_url)}
            />
          );
        }

        if (currentContent === 'CartaoContent') {
          const { val_cobranca } = row;
          const { lastFourDigits, expiryMonth, expiryYear, creditCardBrand } =
            row.forma_pagamento.des_dados_json.creditCard;

          return (
            <CardContent
              creditCardBrand={creditCardBrand}
              lastDigits={lastFourDigits}
              dueDate={`${expiryMonth}/${expiryYear}`}
              price={val_cobranca}
            />
          );
        }

        if (paymentSuccess) return <PaymentStatus />;

        return <div>Conteúdo não encontrado.</div>;
      };

      const renderModal = async (customClass: string) => {
        await MySwal.fire({
          title: 'Pagar fatura',
          showCancelButton: false,
          showConfirmButton: !isBoleto,
          confirmButtonText: 'Trocar Forma de Pagamento',
          cancelButtonText: 'Pagar fatura',
          html: renderContent(),
          width: customClass === 'modal-wide' ? 1300 : 600,
          customClass: {
            confirmButton: 'swal2-confirm',
            cancelButton: 'swal2-cancel',
          },
        }).then((res) => {
          if (res.isConfirmed) {
            handleScreen('MUDAR-FORMA-PAGAMENTO');
          }
        });
      };

      renderModal('modal-default');
    },
    [handleScreen, setChaveBoleto],
  );

  const getTipoFormaPagto = (tipo_forma_pagto: number) => {
    switch (Number(tipo_forma_pagto)) {
      case 0:
        return 'Boleto';
      case 1:
        return 'Cartão de Crédito';
      case 2:
        return 'PIX';
      default:
        return 'Não Identificada';
    }
  };

  const getTipoStatus = (tipo_status: number): JSX.Element => {
    switch (Number(tipo_status)) {
      case 0:
        return (
          <button
            className="vencido text-white font-bold py-1 px-4 rounded"
            type="button"
          >
            Vencida
          </button>
        );
      case 1:
        return (
          <button
            className="a-vencer text-white font-bold py-1 px-4 rounded"
            type="button"
          >
            À Vencer
          </button>
        );
      case 2:
        return (
          <button
            className="pago text-white font-bold py-1 px-4 rounded"
            type="button"
          >
            Pago
          </button>
        );
      default:
        return (
          <button
            className="bg-stone-500 text-white font-bold py-1 px-4 rounded"
            type="button"
          >
            Não identificado
          </button>
        );
    }
  };

  const handleTableAction = useCallback(
    async (fatura: Fatura) => {
      if (fatura.des_status === 'Pago') {
        const comprovante = fatura.des_fatura_comprovante_url;
        if (!fatura.des_fatura_comprovante_url) {
          toast.warning(
            'Não foi possível recuperar o comprovante de fatura. Tente novamente mais tarde. Caso o problema persista, entre em contato com nosso suporte.',
          );
          return;
        }
        getComprovante(String(comprovante));
        return;
      }
      await modalAlert(fatura);
    },
    [modalAlert],
  );

  if (screen === 'HOME') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          cursor: `${
            isDemonstracao && !tipoStatus.contratacaoTransitoria
              ? 'not-allowed'
              : 'pointer'
          }`,
        }}
      >
        {/* isDemonstracao */}
        <FaFileInvoiceDollar
          size={130}
          color={`${
            isDemonstracao && !tipoStatus.contratacaoTransitoria
              ? '#9c9c9c'
              : '#28A745'
          }`}
        />
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
          }}
        >
          Faturas
        </p>
      </div>
    );
  }

  return (
    <FaturasContent>
      <HeaderScreen showButtonVoltar={screen === 'FATURAS'} />
      <ScreenName name="Faturas" />
      <div className="content">
        <Box sx={{ height: 380, width: '100%' }}>
          <div className="container mx-auto p-4">
            <table className="table-auto w-full border-collapse border border-gray-200 shadow-sm">
              <thead>
                <tr className="bg-purple-500 text-white">
                  <th className="px-4 py-2 border">Data de Vencimento</th>
                  <th className="px-4 py-2 border">Descrição</th>
                  <th className="px-4 py-2 border">Valor</th>
                  <th className="px-4 py-2 border">Status</th>
                  <th className="px-4 py-2 border">Forma Pagamento</th>
                  <th className="px-4 py-2 border">Ação</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((fatura) => (
                  <tr
                    key={fatura.id}
                    className="hover:bg-gray-100 transition-colors"
                  >
                    <td className="px-4 py-2 border text-center">
                      {fatura.dta_vencimento}
                    </td>
                    <td className="px-4 py-2 border text-left">
                      {fatura.des_nome}
                    </td>
                    <td className="px-4 py-2 border text-center">
                      {fatura.val_cobranca}
                    </td>
                    <td className="px-4 py-2 border text-center">
                      {getTipoStatus(fatura.tipo_status)}
                    </td>
                    <td className="px-4 py-2 border text-center">
                      {getTipoFormaPagto(fatura.tipo_forma_pagamento)}
                    </td>
                    <td className="px-4 py-2 border text-center">
                      {fatura.tipo_forma_pagamento === 1 && (
                        <button
                          className="action text-white font-bold py-1 px-4 rounded"
                          type="button"
                          onClick={async () => {
                            await handleTableAction(fatura);
                          }}
                        >
                          {fatura.des_status === 'Pago'
                            ? 'Baixar Comprovante'
                            : 'Ir para o pagamento'}
                        </button>
                      )}
                      {fatura.tipo_forma_pagamento === 0 &&
                        fatura.des_boleto_url === null && (
                          <span
                            className="action font-bold py-1 px-4 rounded"
                            style={{
                              background: '#fbbf24',
                            }}
                          >
                            Boleto Pendente
                          </span>
                        )}
                      {fatura.tipo_forma_pagamento === 0 &&
                        fatura.des_boleto_url !== null && (
                          <button
                            className="action text-white font-bold py-1 px-4 rounded"
                            type="button"
                            onClick={async () => {
                              await handleTableAction(fatura);
                            }}
                          >
                            {fatura.des_status === 'Pago'
                              ? 'Baixar Comprovante'
                              : 'Ir para o pagamento'}
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Box>
      </div>
    </FaturasContent>
  );
};
